import { Alert, Input, Tooltip, Typography, message } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { myWalletFetch, myWithdrawalFetch } from '../../reducers/user'
//@ts-ignore
import styles from './styles.module.scss';
import { UserIconProfile } from '../../components/SvgSprite'
// import { signOut, useSession } from 'next-auth/react'
import PasswordForm from './PasswordForm'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authSetIsAuthenticated, authSignOut } from '../../reducers/auth';
import { useNavigate } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import { copyToClipboard } from '../../utils/textTools';
import { getDomainUrl } from 'front-end-common/src/utils/envDependent'

const { Title } = Typography

export interface FormProps {
  passwordValue: string,
  payout: boolean,
  title: string,
  payoutPassSet?: boolean
}

const UserSettings = () => {

  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(authSignOut());
    //@ts-ignore
    dispatch(authSetIsAuthenticated(false))
    navigate('/')
  }

  // const session = useSession()
  // const userName = session?.data && session?.data?.user && session?.data?.user?.name 
  const userName = localStorage.getItem('username')
  const referrer = localStorage.getItem('referrer')
  // const userName = 'userName@userName.com'

  useEffect(() => {
    dispatch(myWalletFetch())
    dispatch(myWithdrawalFetch())
  }, [dispatch])

  const handleCopy = () => {
    copyToClipboard(referrer)
    message.success(`Refferal ID ${referrer} has been copied`)
  }

  const handleCopyLink = () => {
    copyToClipboard(`https://clapswap.com/signup?referrer=${referrer}`)
    message.success(`Refferal link has been copied`)
  }

  return (
    <div style={{maxWidth: '1280px', margin: '24px auto'}}>
      <Title level={1}>Profile</Title>
      <div className={styles.wrapper}>
    
        <div className={styles.userProfile}>
          <div style={{marginTop: '43px', marginBottom: '24px'}}>
            <UserIconProfile />
            {/* ICON */}
          </div>
          <span className={styles.userName}>{userName}</span>
          <small className={styles.refferal}>Referral ID: {referrer} <CopyOutlined onClick={handleCopy} /></small>
          <Input value={`${getDomainUrl()}.com/signup?referrer=${referrer}`} disabled addonAfter={<Tooltip title="Copy referral link"><CopyOutlined onClick={handleCopyLink} /></Tooltip>} />
          <br />
          <div className={styles.signOutButton}>
          <a
            onClick={handleLogout}
            className={styles.signin}
          >
            Log Out
          </a>
          </div>
        </div>
      
        <div className={styles.passwordForm}>
        {/* {!payoutPassSet &&<Alert style={{width: '527px', marginTop: '10px'}} message="Payout password is not set. Please use your user password as current password the first time you set your payout password" type="warning" showIcon closable />} */}
        {/* <br /> */}
      <PasswordForm
      passwordValue='userPass'
      payout={false}
      title='Change user password'
      />
      {/* <PasswordForm
      passwordValue='payoutPass'
      payout={true}
      title='Change payout password'
      payoutPassSet={payoutPassSet}
      /> */}
        </div>
        </div>
      
    </div>
  )
}

export default UserSettings
