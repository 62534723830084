import { List, Typography } from 'antd'
import React from 'react';
import { getDomainUrl } from 'front-end-common/src/utils/envDependent'
import { extractDomainName } from '../../utils/extractDomain';

const { Title } = Typography

const usage = [
  'Provide, operate, and maintain our website',
  'Ensure compliance with regulations',
  'Provide our services and support',
  'Verify your identity',
  'Resolve disputes and troubleshoot problems',
  'Improve, personalize, and expand our website',
  'Understand and analyze how you use our website',
  'Develop new products, services, features, and functionality',
  'Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes',
  'Send you emails',
  'Find and prevent fraudulent activities',
];

const dataProtection = [
  'The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.',
  'The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.',
  'The right to erasure – You have the right to request that we erase your personal data, under certain conditions.',
  'The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.',
  'The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.',
  'The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.',
  'If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.',
];

const retention = [
  'Provide our services to you',
  'Archiving purposes to detect and investigate fraudulent transactions',
  'Comply with our legal obligations under financial or anti-money laundering laws',
  'Satisfying any tax, accounting or reporting requirements',
  'Upon our reasonable belief that there is a prospect of litigation in respect to our relationship with you',
];


const getRomanNumeral = (number: number) => {
  const numerals = [
    'i', 'ii', 'iii', 'iv', 'v',
    'vi', 'vii', 'viii', 'ix', 'x', 'xi'
    // Add more Roman numerals as needed
  ];
  return numerals[number - 1] || '';
};

const PrivacyPolicy = () => (
  <div style={{maxWidth: '960px', margin: '24px auto'}}>
    <Title level={1}>Privacy Policy</Title>

    <section className="faq">
      <p>
        
        <p>At <b>AURORA BOREALIS LIMITED LIABILITY COMPANY</b> &#40;referred to as the “Company”, “Us”, or “We”&#41; and creators of {extractDomainName(getDomainUrl())} &#40;referred&nbsp;to as&nbsp;the&nbsp;“Platform” or “Product”&#41;, we prioritize the privacy of our visitors and customers while accessing {extractDomainName(getDomainUrl())}.com (our “Website” or “Mobile&nbsp;Application”).
        <br />
        This Privacy Policy document, which is an agreement between the Company and the User (“You”), outlines the&nbsp;nature&nbsp;of&nbsp;information collected and recorded on the Platform and how we utilize it.
        {/* <br /> */}
        It also signifies our commitment to maintaining your information's confidentiality, the reasons behind data collection, how we store and transfer the data, and with whom we might share the data, if necessary. 
        {/* <br /> */}
        For any further queries or more information about our Privacy Policy, feel free to contact us.
        </p>
        <p>
        This Privacy Policy is applicable to all our activities and is valid for visitors to our website and offices concerning the information that they shared and/or&nbsp;collect in {extractDomainName(getDomainUrl())}.
        </p>
      </p>

      <p>
        <Title level={5}>1. PRIVACY RIGHTS</Title>
        <p>
        <b>1.1.</b> This Privacy Policy outlines your privacy rights concerning our collection, use, storage, sharing, and protection of your personal information.
        <br />
        <b>1.2.</b> If you have created a username, identification code, password, or any other piece of information as part of our access security measures, you must treat such information as confidential and must not disclose it to any third party.
        <br />
        <b>1.3.</b> We reserve the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our opinion you have failed to comply with any of the provisions of this Policy.
        <br />
        <b>1.4.</b> If you suspect that anyone other than you knows your security details, you must promptly notify us.
        </p>
      </p>

      <p>
        <Title level={5}>2. CONSENT</Title>
        <p>By using the Platform or any of our services, you hereby consent to our Policy and any modifications to it over time. We may amend this privacy policy at any time by posting a revised version on our website or by notifying at conspicuous points at our office facilities. The revised version will be effective 7 days after publication.</p>
      </p>

      <p>
        <Title level={5}>3. INFORMATION COLLECTION</Title>
        <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
        <br />
            If you register on the Platform, you will be required to provide your full name, username, your email address, and phone number.
            <br />
            If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
            <br />
            For payment processing, you will be required to provide your bank account details including the bank name, account name, and account number.
            <br />
            For compliance and KYC purposes, you may be required to provide more personal information and documents like ID and BVN.
            </p>
      </p>

      <p>
          <Title level={5}>4. USAGE OF YOUR INFORMATION</Title>
          <u>We use the information we collect in various ways, including to:</u>
          <List
            style={{marginLeft: '24px', marginTop: '12px'}}
            itemLayout="vertical"
            dataSource={usage}
            renderItem={(item, index) => (
              <List.Item style={{ border: 'none', padding: '0'}}>
                <b>{getRomanNumeral(index + 1)}. </b> {item}
              </List.Item>
            )}
          />
        </p>

      <p>
        <Title level={5}>5. LOG FILES</Title>
        <p>
        The Company, through its product {extractDomainName(getDomainUrl())}, follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as part of their hosting services' analytics. The information collected by log files include internet protocol &#40;IP&#41; addresses, browser type, Internet Service Provider &#40;ISP&#41;, date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
        </p>
      </p>

      <p>
        <Title level={5}>6. COOKIES</Title>
        <p>
        Cookies are small files placed on your computer’s hard drive that enables the website to identify your computer as you view different pages. Cookies allow websites and applications to store your preferences in order to present contents, options or functions that are specific to you. Like most interactive websites, our website uses cookies to enable the tracking of your activity for the duration of a session. Our website uses only encrypted session cookies which are erased either after a predefined timeout period or once the user logs out of the platform and closes the browser. Session cookies do not collect information from the user’s computer. They will typically store information in the form of a session identification that does not personally identify the user.
        </p>
      </p>

      <p>
        <Title level={5}>7. DATA SECURITY AND RETENTION</Title>
        <p>
        The security of your Personal Information is important to the Company.
        <br />
        We maintain appropriate internal, technical, and physical controls to ensure that your data are kept safe at the Company. Other security measures include but are not limited to, secure servers, firewalls, data encryption, and granting access only to designated employees. On your part strive to ensure your passwords to your {extractDomainName(getDomainUrl())} Accounts are safe.
        <br />
        We will only retain personal information on our servers for as long as is reasonably necessary as long as we are providing Services to you. When you close your {extractDomainName(getDomainUrl())} Account, your information is stored on our servers to the extent necessary to comply with regulatory obligations and for the purpose of fraud monitoring, detection, and prevention. Where we retain your Business Information/data, we do so in compliance with limitation periods under the applicable law.
        </p>
      </p>
      
    <p>
      <Title level={5}>8. DATA PROTECTION RIGHTS</Title>
      <p>
      We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
      <List
           style={{marginLeft: '24px', marginTop: '12px'}}
            itemLayout="vertical"
            dataSource={retention}
            renderItem={(item, index) => (
              <List.Item style={{ border: 'none', padding: '0'}}>
                <b>{getRomanNumeral(index + 1)}. </b> {item}
              </List.Item>
            )}
          />
          </p>
    </p>

      <p>
        <Title level={5}>
        9. RETENTION OF PERSONAL DATA
        </Title>
        <p>We will only retain your personal information for as long as reasonably necessary to:
        <List
           style={{marginLeft: '24px', marginTop: '12px'}}
            itemLayout="vertical"
            dataSource={dataProtection}
            renderItem={(item, index) => (
              <List.Item style={{ border: 'none', padding: '0'}}>
                <b>{getRomanNumeral(index + 1)}. </b> {item}
              </List.Item>
            )}
          /></p>
      </p>

      <p>
        <Title level={5}>10. CHILDREN'S INFORMATION</Title>
        <p>{extractDomainName(getDomainUrl())} does not knowingly collect any Personal Identifiable Information from children underthe age of 13, and our service is not available for individuals under the age of 18. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
      </p>

      <p>
        <Title level={5}>11. UPDATES TO OUR PRIVACY POLICY</Title>
        <p>
        In order to keep up with legislation and best practices, we may change, amend or review this Privacy Policy from time to time and place any updates on this page. All changes made will be posted on this page and where changes will materially affect you, we will notify you of this change by placing a notice online or via mail. If you continue using our Services, you consent to all amendments of this Privacy Policy.
        </p>
      </p>
      <p>
        <Title level={5}>12. CONTACT DETAILS</Title>
        <p>
        Email: <a href={`mailto:support@${extractDomainName(getDomainUrl())}.com`}>support@{extractDomainName(getDomainUrl())}.com</a>
        <br />
      This page was last updated in April 2024.
        </p>
      </p>
    </section>
  </div>
)

export default PrivacyPolicy
