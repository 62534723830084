import { Rate, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import useMediaQuery from '../../utils/useMediaQuery'
//@ts-ignore
import styles from './styles.module.scss'
import currencySymbolsCollection from '../../utils/currencySymbolsCollection.json'
import ratesToDisplay from '../../utils/ratesToDisplay'
import CustomImage from '../../components/CustomImage'
import SignIn from '../../components/SignIn'
import { shallowEqual, useSelector } from 'react-redux'
import { fetchContacts } from '../../reducers/noAuth'
import { useDispatch } from 'react-redux'
import { platformGroupsFetch } from '../../reducers/submitCodes'
import PlatformCard from '../../components/PlatformCard'
import { getDomainUrl } from 'front-end-common/src/utils/envDependent'
import { extractDomainName } from '../../utils/extractDomain'


const currencies = ['USDT', 'BTC', 'NGN']
const platforms = [
  
  {title: 'XBox', resellTitle: 'R-XBox', logo: '/xbox_logo_large.png',  logoWebp: '/xbox_logo_large.svg', rate: 54, ngn: '₦41878', width: 100, height: 30, currencySymbol: 'R$', currency: 'BRL'}, 
  {title: 'Steam', resellTitle: 'R-Steam', logo: '/steam_logo_large.png', logoWebp: '/steam_logo_large.svg', rate: 57, ngn: '₦43478', width: 100, height: 30, currencySymbol: 'Col$', currency: 'COP'}, 
  {title: 'Blizzard', resellTitle: 'R-Blizzard', logo: '/blizzard_logo_large.svg',  logoWebp: '/blizzard_logo_large.webp', rate: 42, ngn: '₦27954', width: 80, height: 42, currencySymbol: '€', currency: 'EUR'},
  {title: 'Apple', resellTitle: 'R-Apple', logo: '/apple_logo_large.svg', logoWebp: '/apple_logo_large.svg', rate: 60, ngn: '₦45622', width: 50, height: 40, currencySymbol: '$', currency: 'USD'}
]

const faq = [
  {
    // title: 'What are actual rates?',
    title: 'How can I check the current rates?',
    // content: 'To find out actual rates for cards:',
    linkLine: '1. Register on the platform.',
    secondLine: '2. Choose the brand and currency of your gift card.',
    thirdLine: '3. Enter the code.',
    fourthLine: '4. After submission, wait for the payment to be deposited into your wallet.',
    fifthLine: '5. Withdraw to your bank or cryptocurrency account.',
    color: '#FFF4CC'
  },
  {
    title: 'How does card validation work?',
    content: 'All cards go through automatic verification using our ACVS (Automatic Card Validation System).',
    secondLine: 'Used or already redeemed cards will not pass the verification process. The success rate for such codes on our platform is 0%.',
    color: '#E5F9FF'
  },
  {
    title: 'When will I receive my money?',
    content: 'Xbox, Steam, and Blizzard gift cards are processed instantly.',
    secondLine: 'Validation for other cards may take as little as 2 hours. After the card is verified and confirmed to be valid, it will be transferred to the payment system immediately.', 
    color: '#E7FFDB'
  },
]

const reviews = [
  {
    avatar: '/avatar_one.png',
    avatarWebp: '/avatar_one.webp',
    name: 'Ann Faaron',
    link: '@faronann',
    content: 'A game-changer! I had a stack of unused gift cards from various brands lying around, and this platform helped me turn them into cash effortlessly',
    color: 'rgb(97, 194, 0)'
  },
  {
    avatar: '/avatar_two.png',
    avatarWebp: '/avatar_two.webp',
    name: 'Jamal',
    link: '@jam1999',
    content: `Hi there! Found the perfect way to trade my Nintendo gift card - ${extractDomainName(getDomainUrl())}. Search for the best rates. Chiers!`,
    color: 'rgb(255, 153, 0)'
  },
  {
    avatar: '/avatar_three.png',
    avatarWebp: '/avatar_three.webp',
    name: 'Pierre Van Soletine',
    link: '@solepiero',
    content: `I\'ve been using ${extractDomainName(getDomainUrl())} for a while now, and it never disappoints. Lots of supported brands, including Amazon and Blizzard, ensures that I can always find a buyer for my unwanted gift cards.`,
    color: 'rgb(17, 127, 255)'
  },
  {
    avatar: '/avatar_four.png',
    avatarWebp: '/avatar_four.webp',
    name: 'Aniston Hashley',
    link: '@anihash',
    content: `I\'ve come across the perfect platform for trading my Nintendo gift card - ${extractDomainName(getDomainUrl())}. It allows you to search for the best exchange rates`,
    color: 'rgb(243, 139, 183)'
  },
  {
    avatar: '/avatar_five.png',
    avatarWebp: '/avatar_five.webp',
    name: 'Roberto Santori',
    link: '@rsantorri',
    content: 'I had a collection of unused gift cards from different brands just sitting there, and this platform made it a breeze to convert them into cash.',
    color: 'rgb(111, 191, 225)'
  },
  {
    avatar: '/avatar_six.png',
    avatarWebp: '/avatar_six.webp',
    name: 'Michal Stogov',
    link: '@stogovsfam',
    content: `I recently used ${extractDomainName(getDomainUrl())} and I was incredibly impressed. The process was straightforward, and I received a fair offer for my gift card.`,
    color: 'rgb(208, 155, 241)'
  },
]

const LandingPage = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContacts())
  }, [])

  useEffect(() => {
    dispatch(platformGroupsFetch())
  }, [])

  const { payload } = useSelector((state) => state?.noAuthReducer?.contacts, shallowEqual)

  const { contacts, ngnRates } = payload || {}

const [show, setShow] = useState(false)
const media520 = useMediaQuery('(max-width: 520px)')

const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

const { telegram, whatsApp } = contacts || {}

const nairaRatesToDisplay = ratesToDisplay(ngnRates)
// console.log(nairaRatesToDisplay)

return (

<>
  <>
    <div id="app" className={styles.landing}>
      <div className={styles.giftCards}>
        <h1>Sell Gift Cards for&nbsp;instant with <span>best&nbsp;ra️tes!</span></h1>
        <PlatformCard />
        <a href='/signup'>See all cards and rates</a>
        
        <div className={styles.register}>
          <a className={styles.button} href="/signup">Register to start selling</a>
          <br />
          <span>Already have an account? <SignIn textColor='#1890ff' /></span>
        </div>

      </div>

      {/* Questions block */}
      <div className={styles.questions}>
      <h2>Rates <span>+</span> other questions</h2>

      <div className={styles.questions__faq}>
        {faq.map((card, index) => 
            <div key={index} className={styles.questions__faq_card} style={{ backgroundColor: card.color}}>
              <h3>{card.title}</h3>
              <span>{card.content}</span>
              {/* <br /> */}
              <span><a href='/signup'>{card.linkLine}</a><br/>  {card.secondLine} <br/>{card.thirdLine}<br/>{card.fourthLine}<br/>{card.fifthLine}</span>
            </div>
        
        )}
      </div>
      <a className={styles.button} href="/signup">Start to sell gift cards</a>
      
      </div>

      {/* Reviews block */}
      <div className={styles.reviews}>
        <CustomImage
                src='/like.png'
                srcSet='/like.webp'
                alt='like'
                width={105}
                height={100}
              />
        <h2><span>Positive experience</span> shared by our customers</h2>

        <div className={styles.reviews__cards}>
          {reviews.map((review, index) => 
          (showAll || index < 3 || !media520) ? (
          <div key={index}
          className={styles.reviews__cards_review}
          //@ts-ignore
          style={{ '--review-color': review.color }}
          >
            <div className={styles.reviews__cards_review_bio}>
            <CustomImage
                src={review.avatar}
                srcSet={review.avatarWebp}
                alt='like'
                width={48}
                height={48}
              />
              <div className={styles.reviews__cards_review_bio_names}>
                <span>{review.name}</span>
                {/* <br/> */}
                <small>{review.link}</small>
                
              </div>
              
            </div>
            <Rate value={5} className={styles.rate} style={{ fill: 'yellow'}} disabled />
            <br />
            <span>{review.content}</span>
          </div>
          ) : null
          )}
         
        </div>
        {media520 && !showAll && reviews.length > 3 && (
              <button onClick={toggleShowAll} className={styles.showAllButton}>
                Show all
              </button>
            )}

        <div className={styles.register}>
            <span>Want to share your experience? <a href={`https://api.whatsapp.com/send?phone=${whatsApp}`} rel="noopener noreferrer" target="_blank">Please write us!</a></span>
            <br />
          <a className={styles.button} href="/signup">Sell your cards</a>
         
          
        </div>

      </div>


    </div>
      </>
    </>
    )
  }

  export default LandingPage
