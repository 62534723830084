import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Form, Input, Typography } from "antd";
import { useCallback, useState } from "react";
import { FormProps } from ".";
import { messageError, messageSuccess } from "../../components/Message";
// import { CheckIcon, CheckIconFalse } from "../../components/svgSprite";
import { setNewPassword } from "../../reducers/user";
//@ts-ignore
import styles from './styles.module.scss';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import React from "react";

const { Title } = Typography



const PasswordForm:React.FC<FormProps> = ({ passwordValue, payout, title, payoutPassSet }) => {

  const [isPasswordLong, setIsPasswordLong] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [curPassword, setCurPassword] = useState('');
  const [newPass, setNewPass] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const dispatch: any = useDispatch();
  const navigate = useNavigate();


  const handleChange = useCallback(( { target: { value } }:any) => {
    setCurPassword(value)
  }, [])

  const handleChangeNewPassword = useCallback(( { target: { value } }:any) => {
    setNewPass(value)
    if(value.length >= 8) {
      setIsPasswordLong(true)
    } else {
      setIsPasswordLong(false)
    }
    if (value !== value.toLowerCase()) {
      setIsUpperCase(true)
    } else {
      setIsUpperCase(false)
    }
  }, [])

  const handleSetPassword =
    () => {
      setIsProcessing(true)
      dispatch(setNewPassword(curPassword, newPass, payout))
        .then((res: any) => {
          const result = res
          result.error && messageError(result.error)
          setIsProcessing(false)
          !result.error && messageSuccess(`Successfully changed Password`)
          !result.error && navigate(`/`)
        })
        .catch((error: any) => {
          // console.log(error)
          messageError(error.message)
          setIsProcessing(false)
        })
      }

      const checkValidity = () => {
          return !isPasswordLong || !isUpperCase
        }

  return (
      <Form onFinish={handleSetPassword}>
        <Title style={{alignSelf: 'flex-start'}} level={4}>{title}</Title>

    
        <Form.Item
          name="password"
          // label="Password"
          // hasFeedback
        >
          <Input.Password
          placeholder={'Current password'}
          // value={password}
          onChange={handleChange}
          style={{fontWeight: 'bold'}}
          className={styles.walletInput}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item
          name={passwordValue}
          // label="Password"
          // hasFeedback
        >
        <Input.Password
        placeholder={'New password'}
        // value={newPass}
        onChange={handleChangeNewPassword}
        style={{fontWeight: 'bold'}}
        className={styles.walletInput}
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
        
      </Form.Item>

      <div className={styles.textValidated}>
        
        {/* <span> {isPasswordLong ? <CheckIcon /> : <CheckIconFalse />} Must be 8 characters or longer</span>
        <span> {isUpperCase ? <CheckIcon /> : <CheckIconFalse />} Contain at least one Uppercase letter</span> */}
      </div>
      

        <Form.Item
        name="confirm"
        // label="Repeat a new password"
        dependencies={[passwordValue]}
        // hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!'
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue(passwordValue) === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Passwords mismatch!'));
            },
          }),
        ]}
      >
        <Input.Password 
        placeholder={'Repeat a new password'}
        // value={password}
        // onChange={e => setPassword(e.target.value)}
        style={{fontWeight: 'bold'}}
        className={styles.walletInput}
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>
      <Form.Item>
      {!isProcessing ? ( 
      <Button
        aria-label="Change password button"
        htmlType="submit"
        type="primary"
        className={styles.button}
        disabled={checkValidity()}
        >
          Change
        </Button> 
        ) : (
          <Button className={styles.buttonAnimated} type="primary">
            <div className={styles.loadingiospinnerellipsisxxraaph2hu9}>
              <div className={styles.ldio4nb5p2ohc8k}>
              <div></div><div></div><div></div><div></div><div></div>
            </div>
            </div>
          </Button>
            )}
            </Form.Item>
      </Form>
  )
}

export default PasswordForm
