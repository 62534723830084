import React, {useEffect, useState, useMemo} from 'react'
import {Table, Tooltip, Typography} from 'antd'
import type {ColumnsType} from 'antd/es/table'
import {MessageOutlined} from '@ant-design/icons'
import {UserState} from '../../types/rootState'
import Tag from '../../components/Tag'
import useMediaQuery from '../../utils/useMediaQuery'

interface ListType {
  id: number
  ts: number
  code?: string
  codePackId?: number
  btcAddr?: string
  platform?: string
  amount?: number
  curr?: string
  state: string
  comment?: string
}

type UserWallet = UserState['user']['transactions']

const {Text} = Typography

const MyTransactionsTable: React.FC<UserWallet> = ({payload, isLoading, pagination, onChange}) => {
  const list = useMemo(() => payload?.list || [], [payload])

  const media760 = useMediaQuery('(max-width: 760px)')
  const media580 = useMediaQuery('(max-width: 580px)')

  function GetColumns(): ColumnsType<ListType> {
    return [
      {
        dataIndex: 'ts',
        title: 'CREATED',
        align: 'left',
        width: 80,
        render: (date) =>
          new Date(+date).toLocaleString('ru', {
            hour: 'numeric',
            minute: 'numeric',
            day: 'numeric',
            year: 'numeric',
            month: 'numeric',
          }),
      },
      {
        dataIndex: 'code',
        title: 'ORIGIN',
        align: 'center',
        width: 300,
        className: 'ellipsis',
        render: (code, record) => {
          return (
            record.codePackId ? (
                <a href={`/my-codes/${record.codePackId}`} className="tableLink">
                  <Tooltip placement="topLeft" title={code}>
                    {code}
                  </Tooltip>
                </a>
            ) : record.btcAddr ? (
              <a href={`https://www.blockchain.com/btc/address/${record.btcAddr}`}
                 className="tableLink"
                 rel="noopener noreferrer"
                 target="_blank">
                {record.btcAddr}
              </a>
            ) : (<Text>ADMIN</Text>)


          )
        },
      },
      {
        dataIndex: 'amount',
        title: 'AMOUNT',
        align: 'center',
        width: 80,
      },
      {
        dataIndex: 'curr',
        title: 'CURRENCY',
        align: 'center',
        width: 80,
      },
      {
        dataIndex: 'state',
        title: 'STATE',
        align: 'center',
        width: 80,
        render: (status) => (
          <div>
          {status === 'Cancelled' ? (
            <Tag color="red" text={status}/>
          ) : status === 'Finished' ? (
           <Tag color="green" text={status}/>
          ) : status === 'Pending' ? (
             <Tag color="yellow" text={status}/>
          ) : (
             <Tag text={status}/>
          )}
          </div>
          )
        },
      {
        dataIndex: 'comment',
        title: 'NOTES',
        align: 'left',
        width: 80,
        className: 'ellipsis',
        render: (comment) => (
          <Tooltip placement="topLeft" title={comment}>
            {comment}
          </Tooltip>
        ),
      },
    ]
  }

  function GetMobileColumns(): ColumnsType<ListType> {
    return [
      {
        dataIndex: 'ts',
        title: 'ORIGIN',
        align: 'left',
        width: 300,
        render: (date, record) => (
          <>
            <span>
              {new Date(+date).toLocaleString('ru', {
                hour: 'numeric',
                minute: 'numeric',
                day: 'numeric',
                year: 'numeric',
                month: 'numeric',
              })}
            </span>
            <br/>
              <a href={`/my-codes/${record.codePackId}`} className="tableLink">
                {record.code}
              </a>
            <div style={{width: '110px'}}>
              {record.state === 'Cancelled' ? (
                <Tag color="red" text={record.state}/>
              ) : record.state === 'Finished' ? (
                <Tag color="green" text={record.state}/>
              ) : record.state === 'Pending' ? (
                <Tag color="yellow" text={record.state}/>
              ) : (
                <Tag text={record.state}/>
              )}
            </div>
          </>
        ),
      },
      {
        dataIndex: 'amount',
        title: 'AMOUNT',
        align: 'center',
        width: 130,
        render: (amount, record) => `${amount} ${record.curr}`,
      },
      {
        dataIndex: 'comment',
        title: 'NOTES',
        align: 'center',
        width: 100,
        className: 'ellipsis',
        render: (comment) => (
          <Tooltip placement="topLeft" title={comment}>
            {comment}
          </Tooltip>
        ),
      },
    ]
  }

  function GetXSMobileColumns(): ColumnsType<ListType> {
    return [
      {
        dataIndex: 'ts',
        title: 'ORIGIN',
        align: 'left',
        width: 220,
        render: (date, record) => (
          <>
            <span>
              {new Date(+date).toLocaleString('ru', {
                hour: 'numeric',
                minute: 'numeric',
                day: 'numeric',
                year: 'numeric',
                month: 'numeric',
              })}
            </span>
            <br/>
              <a href={`/my-codes/${record.id}`} className="tableLink">
                {record.code}
              </a>
            <div style={{width: '110px', marginTop: '6px'}}>
              {record.state === 'Cancelled' ? (
                <Tag color="red" text={record.state}/>
              ) : record.state === 'Finished' ? (
                <Tag color="green" text={record.state}/>
              ) : record.state === 'Pending' ? (
                <Tag color="yellow" text={record.state}/>
              ) : (
                <Tag text={record.state}/>
              )}
            </div>
            {record.comment && (
              <>
                <MessageOutlined style={{marginRight: '5px', marginTop: '15px'}}/>
                {record.comment}
              </>
            )}
          </>
        ),
      },
      {
        dataIndex: 'amount',
        title: 'AMOUNT',
        align: 'center',
        width: 100,
        render: (amount, record) => `${amount} ${record.curr}`,
      },
    ]
  }

  const [columns, setColumns] = useState(GetColumns())

  useEffect(() => {
    if (media580) setColumns(GetXSMobileColumns())
    else if (media760) setColumns(GetMobileColumns())
    else setColumns(GetColumns())
  }, [payload, media760, media580])

  return (
    <Table
      className="myTransactionsTable"
      size={media760 ? 'small' : 'large'}
      rowKey={(record) => record.id}
      dataSource={list}
      columns={columns}
      loading={isLoading}
      // scroll={{y: 'calc(100vh - 125px)', x: 'max-content'}}
      pagination={pagination}
      onChange={(a, b, c) => onChange(a, b, c)}
    />
  )
}

export default MyTransactionsTable
