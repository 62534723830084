import { Button, Checkbox, Form, Input, Spin, Typography, message } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
//@ts-ignore
import styles from './styles.module.scss';
import { RightOutlined } from '@ant-design/icons';
import { messageError, messageSuccess } from "../../components/Message";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../reducers/user";
import Cookies from 'js-cookie';
import { authSetIsAuthenticated } from "../../reducers/auth";

const { Title } = Typography

export default function LoginPage({ isAuthEvent, setIsAuthEvent }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [remember, setRemember] = useState(false)
  const buttonRef = useRef(null);

  
  const handleRememberUser = useCallback((e) => {
    setRemember(e.target.checked)
  }, []);

  const handleSubmit = () => {
    setIsProcessing(true);
    dispatch(loginUser(username, password))
      //@ts-ignore
      .then((res) => {
        if (!res.error) {
        const { name, token, referrer } = res
        Cookies.set('auth_token', token, { expires: remember ? 7 : 1/48 });
        localStorage.setItem('username', name)
        localStorage.setItem('referrer', referrer)
        //@ts-ignore
        dispatch(authSetIsAuthenticated(true))
        setIsProcessing(false);
        navigate('/')
      } else if (res.status === 423) {
        messageError('Failed to authenticate! Please, verify your email!')
        setIsProcessing(false);
      }
      else if (res.status === 500) {
        messageError('Failed to authenticate! Something happened on our end. Please, be patient, we are working on it!')
        setIsProcessing(false);
      }
      })
      .catch((error) => {
        // console.log('ERRORLOGIN', error)
        error.includes("UNAUTHORIZED") ? messageError("Failed to authenticate! Invalid login or password") : error.includes("database") ? messageError(`Failed to authenticate! Something happened on our end. Please, be patient, we are working on it!`) : messageError(`Failed to authenticate! ${error}`);
        setIsProcessing(false);
      })
  };

  const MyIcon = () => {
    return <RightOutlined style={{ color: 'white' }} />;
  }

  return (
    <div className={styles.registerForm}>
      {/* <Title style={{marginBottom: '44px', marginTop: '48px'}}>Sign In</Title> */}
      <div className={styles.header}>
      <h1>Log in</h1>
      <a className={styles.textLink} href='/signup'>Register</a>
      </div>
    <Form onFinish={handleSubmit}>
    <Form.Item name="username" rules={[{ required: true, message: 'Please input your email!' }]}>
      <Input
        type="email"
        id="username"
        placeholder="Email"
        className={styles.registerInput}
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
      <Input
        
        type="password"
        id="password"
        placeholder="Password"
        className={styles.registerInput}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      </Form.Item>
      {errorMsg && <p>{errorMsg}</p>}
      <Form.Item>
      <div className={styles.text}>
        <div><Checkbox className={styles.checkbox} onChange={handleRememberUser}/> Remember me</div>
        <div className={styles.link}><a href={`/`}>Need help with log in?</a>
        </div>
        </div>
      {/* <div className={styles.text}>Don't have an account? Please <a className={styles.textLink} href='/signup'>Sign Up</a></div> */}
      <Button
        aria-label="Log In Button"
        htmlType="submit"
        type="primary"
        disabled={isProcessing}
        className={`${styles.button}`}
        ref={buttonRef}
        >
          {isProcessing ? 'Signing in...' : 'Log In'}
          {/* {MyIcon()} */}
        </Button> 
        </Form.Item>
    </Form>
    </div>
  );
}


