import React, { useEffect, useState, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { SubmitCodesState, UserState } from '../../types/rootState';
import { Typography, Form, Select, Spin} from 'antd';
//@ts-ignore
import styles from './styles.module.scss';
import { platformGroupsFetch } from '../../reducers/submitCodes';
import useMediaQuery from '../../utils/useMediaQuery';
import convertData from '../../utils/convertData';
import convertPlatforms from '../../utils/convertPlatforms';
import SubmitForm from '../../components/SubmitForm';
import filterObject from '../../utils/filterObject';
import { useDispatch } from 'react-redux';
import NotAvailable from '../../components/NotAvailable';

const { Title } = Typography
const { Option } = Select

interface IconData {
  [key: string]: string
}

export interface TableProps {
  item: [string, {
    [key: string]: [{
      [key: string]: string | number
    }]
  }],
  icons: IconData
}

const icons: IconData = {
  Airbnb: 'logo_airbnb.svg',
  Apple: 'logo_apple.svg',
  BestBuy: 'logo_bestbuy.svg',
  XBox: 'logo_xbox.svg',
  PSN: 'logo_psn.svg',
  Amazon: 'logo_amazon.svg',
  Steam: 'logo_steam.svg',
  Blizzard: 'logo_blizzard.svg',
  Spotify: 'logo_spotify.svg',
  IKEA: 'logo_ikea.svg',
  BarnesAndNoble: 'logo_barnesandnoble.svg',
  Zalando: 'logo_zalando.svg',
  Ebay: 'logo_ebay.svg',
  Hulu: 'logo_hulu.svg',
  OldNavy: 'logo_oldnavy.svg',
  KarmaKoin: 'logo_karmakoin.svg',
  Macys: 'logo_macys.svg',
  MYTOYS: 'logo_mytoys.svg',
  Nintendo: 'logo_nintendo.svg',
  Gap: 'logo_gap.svg',
  Google: 'logo_google.svg',
  Netflix: 'logo_netflix.svg',
  Starbucks: 'logo_starbucks.svg',
  Walmart: 'logo_walmart.svg',
  Hotels: 'logo_hotels.svg',
  Default: 'logo_default.svg',
}

const instructions: IconData = {
  BestBuy: 'Up to 20 codes in one time. One code per line. Сode  format ‘XXXXXXXXXXXXX-XXXX:100’, with numbers for code and pin (separated by -) and a card’s amount after colon.',
  XBox: 'Up to 20 codes in one time. One code per line. Only codes, no extra characters, etc. Invalid characters are A, E, I, O, U, L, S, 0, 1 and 5',
  Amazon: 'Up to 20 codes in one time. One code per line. Code format ‘XXXX-XXXX-XXXX:100’, with the text code (case-insensitive) and a card’s amount after colon.',
  IKEA: 'Up to 20 codes in one time. One code per line. Code format ‘XXXXXXXXXXXXX-XXXX:100’, with numbers for code and pin (separated by -) and a card’s amount after colon.',
  BarnesAndNoble: 'Up to 20 codes in one time. One code per line. Code format ‘XXXXXXXXXXXXX-XXXX:100’, with numbers for code and pin (separated by -) and a card’s amount after colon.',
  Zalando: 'Up to 20 codes in one time. One code per line. Code format ‘XXXXXXXXXXXXX:100’, with numbers for code and a card’s amount after colon.',
  Airbnb: 'Up to 20 codes in one time. One code per line. Code format ‘XXXXXXXXXXXXX:100’, with numbers for code and a card’s amount after colon.',
  OldNavy: 'Up to 20 codes in one time. One code per line. Code format ‘XXXXXXXXXXXXX-XXXX:100’, with numbers for code and pin (separated by -) and a card’s amount after colon.',
  Macys: 'Up to 20 codes in one time. One code per line. Code format ‘XXXXXXXXXXXXX-XXXX:100’, with numbers for code and pin (separated by -) and a card’s amount after colon.',
  Gap: 'Up to 20 codes in one time. One code per line. Code format ‘XXXXXXXXXXXXX-XXXX:100’, with numbers for code and pin (separated by -) and a card’s amount after colon.',
  Starbucks: 'Up to 20 codes in one time. One code per line. Code format ‘XXXXXXXXXXXXX-XXXX:100’, with numbers for code and pin (separated by -) and a card’s amount after colon.',
  Walmart: 'Up to 20 codes in one time. One code per line. Code format ‘XXXXXXXXXXXXX-XXXX:100’, with numbers for code and pin (separated by -) and a card’s amount after colon.',
  Hotels: 'Up to 20 codes in one time. One code per line. Code format ‘XXXXXXXXXXXXXXXX-XXXXXXXX’, with 16 numbers for code and 8 numbers for pin (separated by -)',
  Default: 'Up to 20 codes in one time. One code per line. Only codes, no extra characters, etc.',
}

const SubmitCodes = () => {

  const freezeData = useSelector((state) => state?.noAuthReducer?.contacts, shallowEqual)
  const freeze = freezeData?.payload?.freeze && freezeData?.payload?.freeze;

  const dispatch: any = useDispatch();

  useEffect(() => {
    try {
     const res = dispatch(platformGroupsFetch())
    }
    catch (error) {
      // console.log('ERROR', error)
    }
  }, [dispatch])

  const { payload, isLoading } = useSelector((state: SubmitCodesState) => state?.submitCodes?.platformsList) || {}

  const { balance } = useSelector((state: UserState) => state?.user) || {}

  // console.log('balance', balance)

  const currency = balance?.payload?.currency

  // console.log('currency', currency)

  const groups = payload?.platformGroups
  const cards = payload?.supportedCards
  const showRate = payload?.showCustomRate
  const ngnRate = payload?.ngnRateMult
  const usdRates = payload?.usdRates
  const ngnRates = payload?.ngnRates
  const countryCurrencies = payload?.countryCurrencies

  const filterGroupsWithNoData = filterObject(groups)
  const filterCardsWithNoData = filterObject(cards)

  const convertedCardList = convertData(cards)
  const convertedGroups = convertPlatforms(filterGroupsWithNoData)
  const newConvertedCardList = convertPlatforms(filterCardsWithNoData)

  const platformsList = convertedGroups && Object.keys(convertedGroups)
  const platformsListFiltered = newConvertedCardList && Object.keys(newConvertedCardList)
  const newConvCardList = newConvertedCardList && Object.entries(newConvertedCardList)

  // console.log('platformsListSorted', platformsListFiltered)
  // console.log('newConvCardList', newConvCardList)

      // Сортировка массива по заданным платформам
      const mainPlatforms = ['XBox', 'Blizzard', 'Steam', 'Amazon', 'Google', 'Netflix', 'Nintendo', 'PSN', 'Zalando'];

      const existingSpecificElements = mainPlatforms.filter(item => platformsListFiltered?.includes(item));

      // Sorting the remaining elements alphabetically
      const remainingPlatforms = platformsListFiltered?.filter(item => !mainPlatforms.includes(item)).sort();

      // Combining the existing specific elements and the remaining elements
      const sortedList = [...existingSpecificElements, ...remainingPlatforms as string[]];


    // const remainingPlatforms = platformsListFiltered.filter(item => !mainPlatforms.includes(item)).sort();
    // const sortedList = [...mainPlatforms, ...remainingPlatforms];

  
  // getXbox()
  //===========================================================

  const defaultPlatform = 'XBox'

  const [curPlarformValue, setCurPlarformValue] = useState('')

  // console.log('defaultPlatform', defaultPlatform)
  

  useEffect(() => {
    defaultPlatform && setCurPlarformValue(defaultPlatform)
  }, [defaultPlatform])

  const countryList = useMemo(
    () => groups && curPlarformValue && groups?.[curPlarformValue],
    [curPlarformValue, groups]
  )

  const defaultCountry = countryList && countryList[0]

  const [curCountryValue, setCurCountryValue] = useState('')
  

  useEffect(() => {
    defaultCountry && setCurCountryValue(defaultCountry)
  }, [defaultCountry])

  const media600 = useMediaQuery('(max-width: 600px)')


  // const balance = localStorage.getItem('balance')
  // const storedCurrency = balance !== null && JSON.parse(balance)?.currency

  // console.log('balanceSubmit', balance)
  // console.log('storedCurrency', storedCurrency)

  return (
    <div className={styles.submitWrapper} style={{maxWidth: '1280px', margin: '24px auto' }}>
      
      {isLoading ? (
              <Spin style={{ margin: '25px' }} />
            ) : (
        !freeze ?
        <>
        <Title level={1}>Submit codes</Title>
        <div className={styles.submitCodes}>

          <div className={styles.platformsSelector}>
            {isLoading && curPlarformValue === undefined ? (
              <Spin style={{ margin: '25px' }} />
            ) : (
              <Form.Item className={styles.selector}>
                {media600 ? (
                  <Select
                    // defaultActiveFirstOption
                    defaultValue='XBox'
                    defaultOpen={false}
                    onChange={setCurPlarformValue}
                    virtual={false}
                    
                  >
                    {sortedList && sortedList?.map((item: string) => (
                      <Option value={item} key={item}>
                        <img
                        style={{ paddingRight: '10px', height: '16px' }}
                        src={icons[item] ? icons[item] : icons['Default']}
                        alt='platform logo' />
                        {item}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Select
                    // defaultActiveFirstOption
                    open
                    defaultValue='XBox'
                    onChange={setCurPlarformValue}
                    style={{ width: '100%' }}
                    bordered={false}
                    listHeight={650}
                    dropdownStyle={{
                      padding: '0 16px',
                      width: '220px',
                      boxShadow: 'unset',
                    }}
                  >
                    {sortedList && sortedList.map((item: string) => (
                      <Option value={item} key={item}>
                        <img
                        style={{ paddingRight: '10px', height: '16px' }}
                        src={icons[item] ? icons[item] : icons['Default']}
                        alt='logo' />
                        <span>{item}</span>
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            )}
          </div>

          <div className={styles.wrapper}>
      
        {newConvCardList?.map((item: any, index) => (
              <SubmitForm
              key={index}
              item={item}
              curPlarformValue={curPlarformValue}
              isLoading={isLoading}
              showRate={showRate}
              ngnRate={ngnRate}
              usdRates={usdRates}
              ngnRates={ngnRates}
              countryCurrencies={countryCurrencies}
              instructions={instructions[curPlarformValue] ? instructions[curPlarformValue] : instructions['Default']}
              currency={currency}
              />
            ))
          }
          </div>

        </div></> : <div><NotAvailable /></div>
            )}
    </div>
  )
}

export default SubmitCodes
