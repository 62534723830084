import React, {useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Typography, Table, Tooltip, Space } from 'antd'
import type {ColumnsType} from 'antd/es/table'
import {UserState} from '../../types/rootState'
import {myPayoutsFetch, myWithdrawalFetch} from '../../reducers/user'
import Tag from '../../components/Tag'
import TableFeatures from '../../components/TableFeatures'
import useMediaQuery from '../../utils/useMediaQuery'
import Withdrawal from '../../components/Withdrawal'
import PayoutMethodSwtich from '../../components/PayoutMethodSwitch'

const {Title} = Typography

interface ListType {
  id: number
  createdTs: number
  amountBtc: string
  amountUsd: string
  amountCurrency: string
  state: string
  walletAddr: string
  tx?: string
  comment?: string,
  bankName: string,
  bankAccountFirstName: string,
  bankAccountSecondName: string
}

function getStatusTagColor(statusCode: string) {
  switch (statusCode) {
    case 'Error':
    case 'Cancel':
      return 'rgba(218, 29, 11, 1)';
    case 'Valid':
      return 'rgba(30, 161, 18, 1)';
    case 'New':
    case 'Processing':
      return '#FF9500';
    default:
      return '';
  }
}

const Payouts = () => {
  
  const {payload, isLoading} = useSelector((state: UserState) => state?.user?.payouts) || {}

  const payoutCommon = useSelector((state: UserState) => state?.user?.withdrawal?.payload?.payoutCommon, shallowEqual) || {}


  const defaultSelectedPayoutType = payoutCommon ? Object.keys(payoutCommon).includes('UsdToBtc') ? 'UsdToBtc' : Object.keys(payoutCommon)[0] : null

  const [formType, setFormType] = useState(defaultSelectedPayoutType);
  const handleChangeForm = (e) => {
    setFormType(e.target.value);
  }

  const dispatch = useDispatch()

  const { pagination, onChange } = TableFeatures();

  useEffect(() => {
    dispatch(myPayoutsFetch())
    dispatch(myWithdrawalFetch())
  }, [dispatch])

  

  const media760 = useMediaQuery('(max-width: 760px)')
  const media580 = useMediaQuery('(max-width: 580px)')

  const { allPayoutsList } = useMemo(
    () => ({
      allPayoutsList: (payload && payload.list) || [],
    }),
    [payload]
  )

  // TABLE FOR BTC WALLET
  function GetColumns(): ColumnsType<ListType> {
    return [
      {
        dataIndex: 'createdTs',
        title: 'Created',
        align: 'left',
        width: 180,
        render: (date) =>
          new Date(+date).toLocaleString('ru', {
            hour: 'numeric',
            minute: 'numeric',
            day: 'numeric',
            year: 'numeric',
            month: 'numeric',
          }),
      },
      {
        dataIndex: 'amount',
        title: 'Amount',
        align: 'left',
        // className: 'amount',
        width: 150,
        render: (text, record) => 
        <Typography>{record.amountUsd}{record.amountCurrency === 'USD' ? '$' : '₦'} - {record.amountBtc}฿</Typography>
      },
      {
        dataIndex: 'walletAddr',
        title: 'BTC Wallet',
        align: 'left',
        width: 450,
        // className: 'wallet',
        render: (walletAddr) => (
          <a
            href={formType === "ToUsdt" ? `https://tronscan.org/#/address/${walletAddr}` : `https://www.blockchain.com/btc/address/${walletAddr}`}
            className="tableLink"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Tooltip placement="topLeft" title={walletAddr}>
              {walletAddr}
            </Tooltip>
          </a>
        ),
      },
      {
        dataIndex: 'state',
        title: 'Status',
        align: 'left',
        width: 280,
        render: (status, record) => {

          let tagColor = getStatusTagColor(status);
          let tagText = status;
          
          let commentSection, txSection;
      
          if (record.tx) {
            const txTooltip = <Tooltip placement="topLeft" title={record.tx}>
              {record.tx.length === 4 ? (
                <span>{record.tx}</span>
              ) : (<span>{record.tx.slice(0,35)}...</span>)}
            </Tooltip>;
      
            txSection = (
              <a href={formType === "ToUsdt" ? `https://tronscan.org/#/address/${record.tx}` : `https://www.blockchain.com/btc/tx/${record.tx}`} className="tableComment" rel="noopener noreferrer" target="_blank">
                {txTooltip}
              </a>
            );
          }
      
          if (record.comment) {
            const commentTooltip = <Tooltip placement="topLeft" title={record.comment}>
              {record.comment.slice(0, 35) + '...'}
            </Tooltip>;
      
            commentSection = (
              <a className="tableComment" rel="noopener noreferrer" target="_blank">
                {commentTooltip}
              </a>
            );
          }

          if (!record.tx && !record.comment) {
            commentSection = (
              <span>
                <br />
              </span>
            )
          }
      
          if (tagColor) {
            const tag = <span style={{color: tagColor}}>{tagText}</span>;
      
            return (
              <div>
                {tag}
                <br />
                {txSection}
                {commentSection}
              </div>
            );
          } else {
            const tag = <span>{tagText}</span>;
      
            return (
              <div>
                {tag}
                <br />
                {txSection}
                {commentSection}
              </div>
            );
          }
        },
      },
    ]
  }

  // TABLE FOR BANK ACCOUNT
  function GetBankColumns(): ColumnsType<ListType> {
    return [
      {
        dataIndex: 'createdTs',
        title: 'Created',
        align: 'left',
        width: 180,
        render: (date) =>
          new Date(+date).toLocaleString('ru', {
            hour: 'numeric',
            minute: 'numeric',
            day: 'numeric',
            year: 'numeric',
            month: 'numeric',
          }),
      },
      {
        dataIndex: 'amount',
        title: 'Amount',
        align: 'left',
        // className: 'amount',
        width: 150,
        render: (text, record) => 
        <Typography>{record.amountUsd}{record.amountCurrency === 'USD' ? '$' : '₦'}</Typography>
      },
      {
        dataIndex: 'bankAccountNumber',
        title: 'Bank and Account',
        align: 'left',
        width: 450,
        className: 'bank',
        render: (bankAccountNumber, record) => (
          <>
          <div>
            <Tooltip placement="topLeft" title={bankAccountNumber}>
              {bankAccountNumber}
            </Tooltip>
            <br />
            <span style={{fontSize: '12px', color: 'grey'}}>{record.bankName}</span>
            <br />
            <span style={{fontSize: '12px', color: 'grey'}}>{record.bankAccountFirstName} {record.bankAccountSecondName}</span>
          </div>
          
          </>
        ),
      },
      {
        dataIndex: 'state',
        title: 'Status',
        align: 'left',
        width: 280,
        render: (status, record) => {

          let tagColor = getStatusTagColor(status);
          let tagText = status;
          
          let commentSection, txSection;
      
          if (record.tx) {
            const txTooltip = <Tooltip placement="topLeft" title={record.tx}>
              {record.tx.length === 4 ? (
                <span>{record.tx}</span>
              ) : (<span>{record.tx.slice(0,35)}...</span>)}
            </Tooltip>;
      
            txSection = (
              <a href={formType === "ToUsdt" ? `https://tronscan.org/#/address/${record.tx}` : `https://www.blockchain.com/btc/tx/${record.tx}`} className="tableComment" rel="noopener noreferrer" target="_blank">
                {txTooltip}
              </a>
            );
          }
      
          if (record.comment) {
            const commentTooltip = <Tooltip placement="topLeft" title={record.comment}>
              {record.comment.slice(0, 10) + '...'}
            </Tooltip>;
      
            commentSection = (
              <a className="tableComment" rel="noopener noreferrer" target="_blank">
                {commentTooltip}
              </a>
            );
          }

          if (!record.tx && !record.comment) {
            commentSection = (
              <span>
                <br />
              </span>
            )
          }
      
          if (tagColor) {
            const tag = <span style={{color: tagColor}}>{tagText}</span>;
      
            return (
              <div>
                {tag}
                <br />
                {txSection}
                {commentSection}
              </div>
            );
          } else {
            const tag = <span>{tagText}</span>;
      
            return (
              <div>
                {tag}
                <br />
                {txSection}
                {commentSection}
              </div>
            );
          }
        },
      },
    ]
  }

  // TABLE FOR MOBILE
  function GetXSMobileColumns(): ColumnsType<ListType> {
    return [
      {
        dataIndex: 'createdTs',
        title: 'Amount and wallet',
        align: 'left',
        // width: 50,
        className: 'ellipsis',
        render: (date, record) => (
          <>
            <span>
              {new Date(+date).toLocaleString('ru', {
                hour: 'numeric',
                minute: 'numeric',
                day: 'numeric',
                year: 'numeric',
                month: 'numeric',
              })}
            </span>
            <br/>
            <Typography>{record.amountUsd} {record.amountCurrency === 'USD' ? '$' : '₦'} - {record.amountBtc} ฿</Typography>
            {/* <br /> */}
            <a
            href={formType === "ToUsdt" ? `https://tronscan.org/#/address/${record.walletAddr}` : `https://www.blockchain.com/btc/address/${record.walletAddr}`}
            className="tableLink"
            rel="noopener noreferrer"
            target="_blank"
            
          >
            <Tooltip placement="topLeft" title={record.walletAddr}>
            {record.walletAddr}
            </Tooltip>
          </a>
        </>
        )
      },
      {
        dataIndex: 'state',
        title: 'Status',
        align: 'left',
        // width: 50,
        render: (status, record) => {
          let tagColor = getStatusTagColor(status);
          let tagText = status;
      
          let commentSection, txSection;
      
          if (record.tx) {
            const txTooltip = <Tooltip placement="topLeft" title={record.tx}>
              {record.tx.length === 4 ? (
                <span>{record.tx}</span>
              ) : (<span>{record.tx.slice(0,10)}...</span>)}
            </Tooltip>;
      
            txSection = (
              <a href={formType === "ToUsdt" ? `https://tronscan.org/#/address/${record.tx}` : `https://www.blockchain.com/btc/tx/${record.tx}`} className="tableLink" rel="noopener noreferrer" target="_blank">
                {txTooltip}
              </a>
            );
          }
      
          if (record.comment) {
            const commentTooltip = <Tooltip placement="topLeft" title={record.comment}>
              {record.comment.slice(0, 10) + '...'}
            </Tooltip>;
      
            commentSection = (
              <a className="tableLink" rel="noopener noreferrer" target="_blank">
                {commentTooltip}
              </a>
            );
          }
      
          if (tagColor) {
            const tag = <span style={{color: tagColor}}>{tagText}</span>;
      
            return (
              <div>
                {tag}
                <br />
                {txSection}
                {commentSection}
              </div>
            );
          } else {
            const tag = <Tag text={tagText}/>;
      
            return (
              <div>
                {tag}
                {txSection}
                {commentSection}
              </div>
            );
          }
        },
      },
    ]
  }

  // TABLE FOR BANK MOBILE 
  function GetXSMobileColumnsBank(): ColumnsType<ListType> {
    return [
      {
        dataIndex: 'createdTs',
        title: 'Amount and bank',
        align: 'left',
        width: 100,
        className: 'ellipsis',
        render: (date, record) => (
          <>
            <span>
              {new Date(+date).toLocaleString('ru', {
                hour: 'numeric',
                minute: 'numeric',
                day: 'numeric',
                year: 'numeric',
                month: 'numeric',
              })}
            </span>
            <br/>
            <Typography>{record.amountUsd} {record.amountCurrency === 'USD' ? '$' : '₦'} </Typography>
            <a
            href={formType === "ToUsdt" ? `https://tronscan.org/#/address/${record.walletAddr}` : `https://www.blockchain.com/btc/address/${record.walletAddr}`}
            className="tableLink"
            rel="noopener noreferrer"
            target="_blank"
            
          >
            <Tooltip placement="topLeft" title={record.walletAddr}>
            {record.walletAddr}
            </Tooltip>
          </a>
          <span style={{fontSize: '10px', color: 'grey'}}>{record.bankName}, {record.bankAccountFirstName} {record.bankAccountSecondName}</span>
        </>
        )
      },
      {
        dataIndex: 'state',
        title: 'Status',
        align: 'left',
        width: 50,
        render: (status, record) => {
          let tagColor = getStatusTagColor(status);
          let tagText = status;
      
          let commentSection, txSection;
      
          if (record.tx) {
            const txTooltip = <Tooltip placement="topLeft" title={record.tx}>
              {record.tx.length === 4 ? (
                <span>{record.tx}</span>
              ) : (<span>{record.tx.slice(0,10)}...</span>)}
            </Tooltip>;
      
            txSection = (
              <a href={formType === "ToUsdt" ? `https://tronscan.org/#/address/${record.tx}` : `https://www.blockchain.com/btc/tx/${record.tx}`} className="tableLink" rel="noopener noreferrer" target="_blank">
                {txTooltip}
              </a>
            );
          }
      
          if (record.comment) {
            const commentTooltip = <Tooltip placement="topLeft" title={record.comment}>
              {record.comment.slice(0, 10) + '...'}
            </Tooltip>;
      
            commentSection = (
              <a className="tableLink" rel="noopener noreferrer" target="_blank">
                {commentTooltip}
              </a>
            );
          }
      
          if (tagColor) {
            const tag = <span style={{color: tagColor}}>{tagText}</span>;
      
            return (
              <div>
                {tag}
                <br />
                {txSection}
                {commentSection}
              </div>
            );
          } else {
            const tag = <Tag text={tagText}/>;
      
            return (
              <div>
                {tag}
                {txSection}
                {commentSection}
              </div>
            );
          }
        },
      },
    ]
  }



  const [columns, setColumns] = useState(GetColumns())
  const [list, setList] = useState(allPayoutsList)

  const pageLength = list?.length

  useEffect(() => {
    setFormType(defaultSelectedPayoutType)
  }, [payoutCommon])

  useEffect(() => {
    if (formType === 'UsdToBtc') {
        setList(allPayoutsList.filter(p => p.payoutType === 'UsdToBtc'))
        if (media580) setColumns(GetXSMobileColumns())
        else if (media760) setColumns(GetXSMobileColumns())
        else setColumns(GetColumns())
      } else if (formType === 'Bank') {
        setList(allPayoutsList.filter(p => p.payoutType === 'Bank'))
        if (media580) setColumns(GetXSMobileColumnsBank())
        else if (media760) setColumns(GetXSMobileColumnsBank())
        else setColumns(GetBankColumns())
        
      } else if (formType === 'ToUsdt') {
        setList(allPayoutsList.filter(p => p.payoutType === 'ToUsdt'))
        if (media580) setColumns(GetXSMobileColumnsBank())
        else if (media760) setColumns(GetXSMobileColumnsBank())
        else setColumns(GetBankColumns())
      }
  }, [allPayoutsList, media760, media580, formType])


  return (
      <Space
      direction="vertical"
      size="large"
      style={{ display: 'flex', maxWidth: '1280px', margin: '24px auto', width: '85vw' }}
      >
      <Title level={1}>Payouts</Title>
      <Withdrawal withdrawalText={payload?.info?.withdrawalText} />
      <PayoutMethodSwtich handleChangeForm={handleChangeForm} formType={formType} isOutsideOfWithdrawalForm={true} />
      <Table
        className="codePackTable"
        style={{width: '100vw'}}
        rowKey={(record) => record.id}
        dataSource={list}
        columns={columns}
        loading={isLoading}
        // scroll={{y: 'calc(100vh - 125px)', x: 'max-content'}}
        // pagination={pagination(pageLength)}
        pagination={false}
        //@ts-ignore
        onChange={(a, b, c) => onChange(a, b, c)}
      />
      </Space>
  )
}

export default Payouts
