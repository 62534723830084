import { Radio, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
//@ts-ignore
import styles from './styles.module.scss'
import { myBalanceFetch, myWalletFetch, switchCurrency } from '../../reducers/user'
import { shallowEqual, useDispatch } from 'react-redux'
import { messageError } from '../Message'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { currencySwitch } from '../../reducers/submitCodes'
import { isClapswapFlow } from '../../utils/extractDomain'

const UserCurrencySwitcher = ({ payload, isLoading, setIsLoading }) => {

  const dispatch = useDispatch();
  const location = useLocation();
  
  const currencyType = payload?.currency
  // console.log('currencyType', currencyType)
  // console.log('isLoading', isLoading)
  // console.log('payload', payload)

  // const {isLoading} = useSelector((state) => state?.user?.balance, shallowEqual)
  // Use the payload directly without parsing
  const [currency, setCurrency] = useState('');
  // console.log('currency', currency)

  useEffect(() => {
    // Update the currency state when payload changes
    setCurrency(payload?.currency);
  }, [payload]);

  const handleChangeCurrency = ({ target: { value } }: string) => {
    // console.log('CURRENCYSWITCH', value)
    dispatch(currencySwitch(true))
    const data = {walletCurrency: value}
    setCurrency(value)
    // console.log('CURRENCYSWITCH', data)
    
    setIsLoading(true)
    
        dispatch(switchCurrency(data))
        .then((res) => {
          const parsedResult = JSON.parse(res)
          setCurrency(parsedResult?.walletCurrency)
        })
    
   

    // AFTER IT FETCH BALANCE AFTER TIMEOUT

    if (location.pathname === '/wallet') {
      setTimeout(() => {
        dispatch(myWalletFetch())
      }, 1000)
      
    }
    setTimeout(() => {
      setIsLoading(false)
      dispatch(currencySwitch(false))
   }, 2000)
    

      setTimeout(() => {
         dispatch(myBalanceFetch())
        //  setIsLoading(false)
      }, 1000)
  }


  return (
    <>
    <div className={styles.radioGroup}>
      {isClapswapFlow() && 
        <Radio.Group
          onChange={handleChangeCurrency}
          value={currency}
          optionType="button"
          buttonStyle="solid"
          disabled={isLoading}
        >
              <Radio.Button
              name="walletCurrency"
              value="USD"
              className={styles.radio}
              >
                $
              </Radio.Button>
              <Radio.Button
              name="walletCurrency"
              value="NGN"
              className={styles.radio}
              >
                ₦
              </Radio.Button>
        </Radio.Group>
        }
      </div>
    </>
  )
}

export default UserCurrencySwitcher
