import React, { useMemo } from 'react'
import CustomImage from '../CustomImage'
import { shallowEqual, useSelector } from 'react-redux'

const WebAlert = () => {

  const webAlertText = useSelector((state) => state?.noAuthReducer?.contacts?.payload?.webAlert?.webAlertText, shallowEqual);
  const webAlertShown = useSelector((state) => state?.noAuthReducer?.contacts?.payload?.webAlert?.webAlertShown, shallowEqual);

  return (
    <>
      {webAlertShown &&
        <div className="notification-container">
        <div style={{marginBottom: '8px'}}>
          <CustomImage
                            src='/build_icon.jpeg' 
                            srcSet='/build_icon.webp' 
                            width={60}
                            height={40} 
                            alt='Maintenance on website incoming' 
                            />
                            </div>
                  <span className="notification" dangerouslySetInnerHTML={{ __html: webAlertText }} />
              </div>
}
  </>
  )
}

export default WebAlert;
