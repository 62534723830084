import { createAction, createReducer } from '@reduxjs/toolkit'
import frc from 'front-end-common'
import UserService from '../services/UserService'
import TokenService from '../services/TokenService'

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const registerUser = createAction('user/register', (email: string, password: string, country: string, phone: string, userAgree: boolean, utmData: JSON, referrer: number) => ({
  payload: UserService.signUp(email, password, country, phone, userAgree, utmData, referrer),
}))

export const verifyUser = createAction('user/verify', (userId: number, verificationCode: string ) => ({
  payload: UserService.verify(userId, verificationCode)
}))

export const loginUser = createAction('user/login', (username: string, password: string) => ({
  payload: UserService.login(username, password),
}))

export const signOutUser = createAction('auth/sing-out', () => ({
  payload: TokenService.removeToken(),
}));

export const myBalanceFetch = createAction('user/balance', () => ({
  payload: UserService.fetchMyBalance(),
}))

export const myWalletFetch = createAction('user/wallet', () => ({
  payload: UserService.fetchMyWallet(),
}))

export const myPayoutsFetch = createAction('user/payouts', () => ({
  payload: UserService.fetchMyPayouts(),
}))

export const myTransactionsFetch = createAction('user/transactions', (params) => ({
  payload: UserService.fetchMyTransactions(params),
}))

export const myWithdrawalFetch = createAction('user/withdrawal', () => ({
  payload: UserService.fetchMyWithdrawal(),
}))

export const postNewWithdrawal = createAction(
  'user/postWithdrawal',
  (amount: number, payoutType, btcAddress, data) => ({
    payload: UserService.postWithdrawal(amount, payoutType, btcAddress, {...data}),
  })
)

export const setNewPassword = createAction(
  'user/setPassword',
  (currPassword: string, newPassword: string, isPayout: boolean) => ({
    payload: UserService.setUserPassword(currPassword, newPassword, isPayout),
  })
)

export const switchCurrency = createAction('user/change-currency', (value: string) => ({ payload: UserService.changeUserCurrency(value) }))

const initState = {
  isAuthenticated: false,
  register: getDefaultState(),
  verify: getDefaultState(),
  login: getDefaultState(),
  signOut: getDefaultState(),
  balance: getDefaultState(),
  wallet: getDefaultState(),
  payouts: getDefaultState(),
  transactions: getDefaultState(),
  withdrawal: getDefaultState(),
  postWithdrawal: getDefaultState(),
  setPassword: getDefaultState(),
  changeCurrency: getDefaultState(),
}

export const userReducer = createReducer(initState, {
  ...getDefaultHandler(registerUser, 'register'),
  ...getDefaultHandler(verifyUser, 'verify'),
  ...getDefaultHandler(loginUser, 'login'),
  ...getDefaultHandler(signOutUser, 'signOut'),
  ...getDefaultHandler(myBalanceFetch, 'balance'),
  ...getDefaultHandler(myWalletFetch, 'wallet'),
  ...getDefaultHandler(myPayoutsFetch, 'payouts'),
  ...getDefaultHandler(myTransactionsFetch, 'transactions'),
  ...getDefaultHandler(myWithdrawalFetch, 'withdrawal'),
  ...getDefaultHandler(postNewWithdrawal, 'postWithdrawal'),
  ...getDefaultHandler(setNewPassword, 'setPassword'),
  ...getDefaultHandler(switchCurrency, 'changeCurrency'),
})

export default userReducer
